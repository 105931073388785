<template>
  <v-container
    id="listUsers"
    tag="section"
    fluid
    style="
      height: 90%
    "
  >
    <v-row
      class="mb-6"
      justify="center"
      no-gutters
    >
      <v-col
        class="text-center"
        cols="6"
      >
        <base-material-card
          class="px-3 py-3"
          color="#039BE5"
          icon="mdi-card-account-details-outline"
        >
          <v-form v-model="valid">
            <v-text-field
              v-model="name"
              counter="60"
              :rules="[rules.noCoding]"
              label="Nome"
              clearable
              :disabled="inputed()"
            />
            <v-container>
              <v-row
                class="mu-6"
                justify="center"
                no-gutters
              />
            </v-container>
            <v-text-field
              v-model="username"
              counter="60"
              :rules="[rules.noCoding]"
              label="Email"
              :disabled="inputed()"
              clearable
            />
            <v-btn
              color="info"
              class="ml-2"
              text
              @click="searchResult()"
            >
              Buscar
            </v-btn>
          </v-form>
        </base-material-card>
        <div
          v-if="showUser"
        >
          <v-card
            v-for="(person, index) in getUsers"
            :key="index"
            rounded
            class="text-center"
            md="4"
            tile
          >
            <v-card-text>
              <h1 class="text-center font-weight-regular align-center mt-3 mb-3">
                {{ person.name }}
              </h1>
              <br>

              <h4 class="text-center font-weight-regular align-center mt-3 mb-3">
                {{ person.username }}
              </h4>

              <br>
              <h2 class="text-center font-weight-regular align-center mt-3 mb-3">
                Cliente Cadastrado
              </h2>

              <v-select
                v-model="select"
                disabled
                :items="customers"
                item-text="name"
                item-value="_id"
                label="Clinica/Hospital"
                persistent-hint
                return-object
                single-line
              />
              <!-- Troca de Senha -->
              <v-row justify="center">
                <v-dialog
                  v-model="passwordChange"
                  max-width="350"
                >
                  <v-card>
                    <v-card-title class="text-h5 align-center">
                      <v-text-field
                        v-model="newPassword"
                        :rules="[rules.noCoding]"
                        counter="60"
                        label="Nova senha:"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        hint="At least 8 characters"
                        @click:append="show = !show"
                      />
                    </v-card-title>
                    <v-card-actions>
                      <v-btn
                        text
                        color="#F44336"
                        @click="passwordChange=false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        origin="bottom right"
                        text
                        color="#F44336"
                        @click="agreePassword()"
                      >
                        Confirmar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <!-- Deltetar Usuário -->
              <v-row justify="center">
                <v-dialog
                  v-model="deleteConfirmation"
                  max-width="325"
                >
                  <v-card>
                    <v-card-title class="text-h5 align-center">
                      Tem certeza? Não será possível recuperar esta conta.
                    </v-card-title>
                    <v-card-actions>
                      <v-btn
                        text
                        color="#F44336"
                        @click="deleteConfirmation=false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        ref="confirm"
                        text
                        color="#F44336"
                        @click="agreeDelete()"
                      >
                        Confirmar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col
                class="text-center"
              >
                <v-btn
                  text
                  color="#F44336"
                  @click="passwordChanger(person._id)"
                >
                  Mudar senha
                </v-btn>
                <v-btn
                  text
                  color="#F44336"
                  @click="accountDeleter(person._id)"
                >
                  Apagar
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import rules from '../../services/rules'
  export default {
    name: 'ListUsers',
    data () {
      return {
        customers: [],
        select: { id: null, name: null },
        valid: false,
        name: '',
        username: '',
        user: {},
        showUser: false,
        newPassword: '',
        show: false,
        rules: rules,
        deleteConfirmation: false,
        passwordChange: false,
      }
    },
    computed: {
      ...mapGetters('users', ['getUsers']),
      ...mapGetters('customers', ['customerList']),
    },
    methods: {
      ...mapActions('users', ['findUserByName', 'findUserByUsername', 'changePassword', 'deleteUser']),
      ...mapActions('customers', ['listCustomers']),
      async searchResult () {
        if (this.name !== '') {
          await this.findUserByName(this.name)
          if (!this.getUsers || !this.getUsers.length) {
            this.$alert('error', 'Usuário não encontrado.')
          }
        } else if (this.username !== '') {
          this.user = await this.findUserByUsername(this.username)
          if (!this.getUsers || !this.getUsers.length) {
            this.$alert('error', 'Usuário não encontrado.')
          } else {
            await this.listCustomers()
            this.customers = this.customerList
            this.select = this.getUsers[0].myClinic.client[0].customer
            this.showUser = true
          }
        } else {
          this.$alert('error', 'Preencha um dos campos')
        }
        this.showUser = true
      },
      async passwordChanger (id) {
        if (await this.openPasswordChanger()) {
          this.changePassword([this.newPassword, id])
          this.$alert('success', 'Senha alterada com sucesso')
          this.clearInputs()
          this.clicked()
        }
      },
      openPasswordChanger () {
        this.passwordChange = true
        return new Promise((resolve) => {
          this.resolve = resolve
        })
      },
      agreePassword () {
        this.resolve(true)
        this.passwordChange = false
      },
      async accountDeleter (id) {
        if (await this.openDeleteConfirmation()) {
          this.deleteUser(id)
          this.$alert('success', 'Conta deletada')
          this.clearInputs()
        }
      },
      openDeleteConfirmation () {
        this.deleteConfirmation = true
        return new Promise((resolve) => {
          this.resolve = resolve
        })
      },
      agreeDelete () {
        this.resolve(true)
        this.deleteConfirmation = false
      },
      clearInputs () {
        this.user = {}
        this.showUser = false
        this.name = ''
        this.username = ''
        this.newPassword = ''
      },
      inputed () {
        if (this.name && this.username) {
          this.name = ''
          this.username = ''
          this.$alert('error', 'Preencha somente um campo')
        }
      },
    },
  }
</script>
